
import { defineComponent } from "vue";

import CloseIcon from "@/assets/svg/close-icon.svg?inline";
import { WalletDisconnectButton } from "@/plugins/solana-wallets-vue";

export default defineComponent({
  name: "NavMenu",
  data() {
    return {
      data: {
        mint: "Mint",
        myCollection: "My collection",
        catalog: "Catalog",
        myMht: "My MHT",
        getMht: "Get MHT",
        profile: "Profile",
        myFavorite: "My favorite",
      },
    };
  },
  components: {
    CloseIcon,
    WalletDisconnectButton,
  },
  props: {
    handleClose: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("phantom-token");
      this.$router.push("/");
    },
  },
});
