
import { defineComponent } from "vue";

import NavMenuAdmin from "@/components/molecules/admin/NavMenuAdmin.vue";
import Logo from "@/assets/svg/logo-dashboard.svg?inline";
import LogOut from "@/assets/svg/logout.svg?inline";
import MenuIcon from "@/assets/svg/menu-icon.svg?inline";
import NavMenuMob from "@/components/molecules/admin/NavMenuMob.vue";

export default defineComponent({
  name: "HeaderDashboard",
  data() {
    return {
      data: {
        logOut: "Log out",
      },
      mobileMenu: false,
    };
  },
  components: {
    NavMenuAdmin,
    Logo,
    LogOut,
    MenuIcon,
    NavMenuMob,
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
    openMobMenu() {
      this.mobileMenu = true;
    },
    closeMobMenu() {
      this.mobileMenu = false;
    },
  },
});
