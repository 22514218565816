import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e85f498"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-dashboard" }
const _hoisted_2 = { class: "header-dashboard__mob-menu-container" }
const _hoisted_3 = { class: "header-dashboard__burger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_NavMenuMob = _resolveComponent("NavMenuMob")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NavMenuAdmin = _resolveComponent("NavMenuAdmin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MenuIcon, {
          onClick: _ctx.openMobMenu,
          class: "header-dashboard__burger-icon"
        }, null, 8, ["onClick"])
      ]),
      (_ctx.mobileMenu)
        ? (_openBlock(), _createBlock(_component_NavMenuMob, {
            key: 0,
            handleClose: _ctx.closeMobMenu
          }, null, 8, ["handleClose"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo, { class: "header-dashboard__logo" })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_NavMenuAdmin),
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout())),
      class: "header-dashboard__logout"
    })
  ]))
}